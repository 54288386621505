import { ThemeProvider } from '@emotion/react'
import { ExpandCircleDownOutlined } from '@mui/icons-material'
import { IconButton, Typography, createTheme } from '@mui/material'
import { RoomListImages } from 'components/RoomListImages'
import { MRoom } from 'models/room'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const RoomDetail = () => {
    const location = useLocation()

    const roomData: MRoom = JSON.parse(location?.state?.data);

    const color: string = location?.state?.color;

    const navigate = useNavigate()

    const purpleTheme = createTheme({
        palette: {
            primary: {
                main: color || '#000'
            },
        }
    });

    const handleNavigate = () => {
        navigate(-1);
    }

    return (
        <ThemeProvider theme={purpleTheme}>
            <div className='p-8 bg-principal-0 text-principal-650'>
                <RoomListImages serviceCenterImages={roomData?.imagenes} />
                <div className='w-full p-4 bg-white border rounded-md mt-4'>
                    <div className='flex items-center'>
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            onClick={() => handleNavigate()}
                        >

                            <ExpandCircleDownOutlined className='rotate-90' fontSize='large' color='primary' />
                        </IconButton>
                        <Typography color='primary' className='font-bold text-xl pl-2'>{location?.state?.hotelName}</Typography>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='px-11 w-1/2 border-r'>
                            <div className='py-4 text-principal-650  text-justify'
                                dangerouslySetInnerHTML={roomData && { __html: roomData?.servicio?.descripcion }}
                            />
                        </div>
                        <div className='px-11 w-1/2'>
                            <p className='font-bold text-principal-600'>Tus opciones:</p>
                            <p>
                                {roomData?.servicio
                                    ?.descripcionHabitacion &&
                                    roomData?.servicio?.descripcionHabitacion
                                        .split("\n")
                                        .map(
                                            (
                                                linea: string,
                                                i: number
                                            ) => (
                                                <React.Fragment
                                                    key={
                                                        i
                                                    }
                                                >
                                                    {
                                                        linea
                                                    }
                                                    <br />
                                                </React.Fragment>
                                            )
                                        )}
                            </p>
                        </div>
                    </div>
                    {
                        roomData?.iconos?.length > 0 &&
                        <div className='flex flex-wrap flex-row p-2 max-h-10 px-8'>
                            {
                                roomData?.iconos?.map((icon, index) => (
                                    <div className='flex flex-row justify-center items-center p-2' key={index}>
                                        <img
                                            src={
                                                icon?.icono
                                            }
                                            className='w-4 h-4'
                                        />
                                        <p className='pl-2'>{icon?.descripcion}</p>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </ThemeProvider>
    )
}
