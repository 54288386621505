import { useState } from "react";
import cartApi from "../api/cartApi";
import integrationApi from "../api/integrationApi";
import { ServiceData } from "models/serviceData";
import { ServiceImages } from "models/serviceImages";
import { CampoJson } from "models/campoJson";
import { MFile } from "models/MFile";
import { UnavailableDates } from "models/uavailableDate";
import { SearchServiceRequest } from "models/searchServices";
import { ValidationChannel } from "models/validationChannel";
import { ServiceFilter } from "models/serviceFilter";

export const usePurchaseScore = () => {

  const services: string = "puntuacion";
  const qualifyService = async (idService: number) => {

    try {
      const movieServiceRequest = integrationApi.post<any>(
        `${services}/puntuacionCompra/${idService}`
      );
      await Promise.resolve(movieServiceRequest);

    } catch (error) {

    }

  };

  return {
    qualifyService
  };
};