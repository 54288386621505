import { Divider, Typography } from '@mui/material';
import { Cotizante, Solicitud } from 'models/schoolKitModel';
import React, { useEffect, useState } from 'react';

interface SchoolKitSummaryProps {
    beneficiaries: Cotizante[] | undefined;
    request?: Solicitud;
    adjudicationMessage: string | undefined;
    postulationMessage: string | undefined;
}

export const SchoolKitSummary = (props: SchoolKitSummaryProps) => {
    const [valor, setValor] = useState<number>();

    const [adjudicado, setAdjudicado] = useState<boolean>(false);
    const [rechazado, setRechazado] = useState<boolean>(false);
    useEffect(() => {
        if (props.request?.valor) {
            setValor(parseInt(props.request.valor));
        }
        if ((request?.estado === 'GENERADO' ||
            request?.estado === 'RECHAZADO' ||
            request?.estado === 'ADJUDICADO') &&
            request.mostrarAdjudicacionEcommerce === 'S') {
            setAdjudicado(true);

            if (beneficiaries?.some(e => e.estado === 'RECHAZADO')) {
                setRechazado(true);
            }
        }
    }, [props]);

    const {
        beneficiaries,
        request,
        adjudicationMessage,
        postulationMessage,
    } = props;
    return (
        <div className='flex flex-col border rounded-lg items-center overflow-x-auto'>
            <div className='flex bg-principal-350 w-full rounded-t-lg'>
                <div className='w-1/2 text-center p-2'>
                    <Typography color='primary' className='font-bold text-xl'>Número solicitud</Typography>
                    <p className='text-xl font-bold text-principal-600'>{request?.numeroSolicitud}</p>
                </div>
                <Divider orientation="vertical" className='bg-principal-600' />
                <div className='w-1/2 text-center p-2'>
                    <Typography color='primary' className='font-bold text-xl'>Fecha postulación</Typography>
                    <p className='text-xl font-bold text-principal-600'>{request?.fechaPostulacion}</p>
                </div>
            </div>
            <Typography color='primary' className='font-bold text-lg py-2 mt-4'>Beneficiarios</Typography>
            <div className='flex flex-wrap overflow-x-auto  text-center w-full md:w-2/3  border rounded-lg '>
                <div className='flex w-full border-b py-2 '>
                    <div className={' text-center px-2 ' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}><Typography color='primary'>Nombre beneficiario</Typography></div>
                    <div className={' text-center px-2 ' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}><Typography color='primary'>Tipo de documento</Typography></div>
                    <div className={' text-center px-2 ' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}><Typography color='primary'>Número de documento</Typography></div>
                    {adjudicado && <div className={'text-center px-2 ' + (!rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}><Typography color='primary'>Estado</Typography></div>}
                    {rechazado && <div className='w-1/5 text-center px-2 '><Typography color='primary'>Motivo rechazo</Typography></div>}

                </div>
                {beneficiaries && beneficiaries.length > 0 && (
                    <div className='flex flex-wrap w-full'>
                        {beneficiaries.map((beneficiary: Cotizante, i: number) => (
                            <div key={beneficiary.identificacion} className='flex w-full py-2 border-b'>
                                <span className={'flex items-center justify-center  min-w-28' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}>
                                    {beneficiary.nombre}
                                </span>
                                <span className={'flex items-center justify-center  min-w-28' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}>
                                    {beneficiary.abreviatura}
                                </span>
                                <span className={'flex items-center justify-center  min-w-28' + (!adjudicado && ' w-1/3 ') + (adjudicado && !rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}>
                                    {beneficiary.identificacion}
                                </span>
                                {adjudicado &&
                                    <span className={'flex items-center justify-center  min-w-28' + (!rechazado && ' w-1/4 ') + (adjudicado && rechazado && ' w-1/5 ')}>
                                        {beneficiary.estado}
                                    </span>
                                }

                                {adjudicado && beneficiary.estado === 'RECHAZADO' ? (
                                    <div className='flex items-center justify-center w-1/5 min-w-28'>
                                        <span className='flex items-center justify-center w-1/5'>
                                            {beneficiary.motivoRechazo}
                                        </span>
                                    </div>) : (<></>)
                                }

                                {i !== beneficiaries.length - 1 && (
                                    <Divider />
                                )}
                            </div>
                        ))}
                        {adjudicado && (
                            <div className='flex w-full justify-around text-center p-2 bg-principal-350 '>
                                <Typography color='primary' className='font-bold text-xl'>Total: </Typography>
                                {valor && (
                                    <span className='text-xl font-bold text-principal-600'>
                                        {valor?.toLocaleString("es-CO", {
                                            style: "currency",
                                            currency: "COP",
                                            minimumFractionDigits: 0,
                                        })}
                                    </span>
                                )}

                            </div>)}
                    </div>

                )}
            </div>

            <Typography color='primary' className='font-bold text-lg py-2 mt-4'>Comentarios</Typography>
            <div className='w-full px-4 pb-4'>
                <div className='border rounded-lg p-2'>
                    {adjudicado ? (
                        <p>{adjudicationMessage}</p>
                    ) : (
                        <p>{postulationMessage}</p>
                    )}
                </div>
            </div>
        </div>
    );
};