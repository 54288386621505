import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { RoomsRequest } from 'models/roomsRequest';
import { MRoom } from 'models/room';
import { LiquidationPlanRequest } from 'models/liquidationPlanRequest';
import { sessionRequest } from 'models/UpdateProfileForm';


export const useRedirect = () => {

    const [isLoadingUrlUpdateUser, setIsLoadingUrlUpdateUser] = useState(true);

    const urlDireccionamiento: string = "redirect";

    const getUrlUpdateUser = async (sessionRequest: sessionRequest) => {

        setIsLoadingUrlUpdateUser(true);

        const rooms = await integrationApi.post<any>(`${urlDireccionamiento}/getUrlUpdateUserData`, sessionRequest);

        setIsLoadingUrlUpdateUser(false);

        return rooms?.data;
    }

    return {
        isLoadingUrlUpdateUser,
        getUrlUpdateUser,
    }
}