import { MovieServiceInfo } from "models/service";
import React, { useEffect, useState } from "react";
import { Button, ButtonProps, CircularProgress, createTheme, FormControl, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ThemeProvider } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useTheme } from "@emotion/react";
import * as locales from '@mui/material/locale';
import { useService } from "hooks/useService";
import moment from "moment";
import { usePurchaseScore } from "hooks/usePurchaseScore";
import { Loading } from "components/Loading";
import { Clear, Done, DoneAll, Loop, SearchRounded } from "@mui/icons-material";

type SupportedLocales = keyof typeof locales;

interface MovieServiceProps {
}
export const MovieService = ({

}: MovieServiceProps) => {

    const { getMovieService } = useService();
    const { qualifyService } = usePurchaseScore();

    const [data, setData] = useState<any>();

    const [rows, setRows] = useState<MovieServiceInfo[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const [findWord, setFindWord] = useState("");

    const [page, setPage] = React.useState(0);

    const [count, setCount] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [locale, setLocale] = React.useState<SupportedLocales>('esES');

    const theme = useTheme();

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const handleQualify = async (idServicio: number) => {
        setIsLoading(true);
        await qualifyService(idServicio);
        setIsLoading(false);
        find(page, rowsPerPage, findWord);
    };

    const find = async (page: number, rowsPerPage: number, findWord: string) => {
        setIsLoading(true);
        const response = await getMovieService({
            pageNumber: page,
            pageSize: rowsPerPage,
            nombreServicio: findWord
        });
        setData(response);
        setRows(response?.content);
        setCount(response?.totalElements);
        setIsLoading(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        find(page, rowsPerPage, findWord);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        find(0, +event.target.value, findWord);
    };

    const handleClean = () => {
        setFindWord("");
        setPage(0);
        find(0, rowsPerPage, "");
    };

    const handleSearch = async () => {
        setIsLoading(true);
        setPage(0);
        const response = await getMovieService({
            pageNumber: 0,
            pageSize: rowsPerPage,
            nombreServicio: findWord
        });
        setData(response);
        setRows(response?.content);
        setCount(response?.totalElements);
        setIsLoading(false);
    };

    useEffect(() => {
        find(page, rowsPerPage, findWord);
    }, []);

    return (
        <div className="w-full px-4">


            {isLoading ? (
                <div className="h-80 w-full">
                    <Loading />
                </div>
            ) : (
                <>
                    {/* filter*/}
                    <div className="w-full pt-5 my-4 rounded-lg pb-2 bg-white shadow-md shadow-black/10 border border-principal-350">
                        <span className=" px-3 font-bold text-xl text-principal-500 w-full">
                            Filtros
                        </span>
                        <div className="w-full  px-3 py-3">
                            <FormControl className="w-full">
                                <TextField
                                    label="Nombre del servicio"
                                    id="fullName"
                                    className="w-full"
                                    value={findWord}
                                    onChange={(e) => setFindWord(e.target.value)}
                                />
                            </FormControl>
                            <div className="flex flex-wrap py-2 justify-end space-x-2">
                                <SearchButton
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    startIcon={<Clear style={{ fontSize: 16 }} />}
                                    style={{ padding: '6px 12px', fontSize: '12px', minWidth: 'auto' }}
                                    onClick={handleClean}>
                                    Limpiar
                                </SearchButton>
                                <SearchButton
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    startIcon={<SearchRounded style={{ fontSize: 16 }} />}
                                    style={{ padding: '6px 12px', fontSize: '12px', minWidth: 'auto' }}
                                    onClick={handleSearch}>
                                    Buscar
                                </SearchButton>
                            </div>
                        </div>
                    </div>
                    {/* table*/}
                    <div className="w-full p-4 my-4 rounded-lg pb-2 bg-white shadow-md shadow-black/10 border border-principal-350">
                        <ThemeProvider theme={themeWithLocale}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Servicio</strong></TableCell>
                                            <TableCell align="center"><strong>Fecha inicio</strong></TableCell>
                                            <TableCell align="center"><strong>Fecha fin</strong></TableCell>
                                            <TableCell align="center"><strong>Negocio</strong></TableCell>
                                            <TableCell align="center"><strong>Categoria</strong></TableCell>
                                            <TableCell align="center"><strong>Cupo</strong></TableCell>
                                            <TableCell align="center"><strong>Postulados</strong></TableCell>
                                            <TableCell align="center"><strong>Calificaciones</strong></TableCell>
                                            <TableCell align="center"><strong>Asignados</strong></TableCell>
                                            <TableCell align="center"><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.idServicio}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.nombre}
                                                </TableCell>
                                                <TableCell align="center">{moment(row.servFechaInicial).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align="center">{moment(row.servFechaFinal).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align="center">{row.negocio}</TableCell>
                                                <TableCell align="center">{row.categoria}</TableCell>
                                                <TableCell align="center">{row.cupo}</TableCell>
                                                <TableCell align="center">{row.totalPostulaciones}</TableCell>
                                                <TableCell align="center">{row.totalCalificaciones}</TableCell>
                                                <TableCell align="center">{row.totalAsignaciones}</TableCell>
                                                <TableCell align="center">
                                                    {row.calificado === "S" ? (
                                                        <ColorButton
                                                            variant="contained"
                                                            size="small"
                                                            disabled
                                                            startIcon={<DoneAll />}
                                                        >
                                                            Calificado
                                                        </ColorButton>
                                                    ) : row.calificado === "P" ? (
                                                        <ColorButton
                                                            variant="contained"
                                                            size="small"
                                                            disabled
                                                            startIcon={<Loop />}
                                                        >
                                                            En proceso
                                                        </ColorButton>
                                                    ) : (
                                                        <ColorButton
                                                            variant="contained"
                                                            color="success"
                                                            size="small"
                                                            onClick={() => handleQualify(row.idServicio)}
                                                            startIcon={<Done />}
                                                        >
                                                            Calificar
                                                        </ColorButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </ThemeProvider>

                    </div>
                </>
            )}
        </div>
    );
};


const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "white",
    backgroundColor: '#65b32e',
    '&:hover': {
        backgroundColor: '#65b32e',
    },
}));

const SearchButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "white",
    backgroundColor: '#65b32e',
    '&:hover': {
        backgroundColor: '#65b32e',
    },
    fontSize: 20,
    paddingRight: 100,
    paddingLeft: 100,
    borderRadius: 50,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
}));