import React, { StrictMode, useEffect } from "react";
import { Preload } from "pages/Preload/Preload";
import { SnackBarMessage } from "components/SnackBar";
import { Footer } from "pages/Footer/Footer";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "config";
import { Toaster } from "react-hot-toast";

export const App = () => {
  return (
      <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_SITE_KEY ?? ''}
            key={'captchaKey'}
          >
        <Preload />
        <SnackBarMessage />
      <Toaster/>
      <Footer />
      </GoogleReCaptchaProvider>
  );
};
