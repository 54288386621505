import React, { useEffect } from "react";
import { MemoryRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home/Home";
import { NavBar } from "../components/NavBar";
import { LoginPage } from "../auth/pages/Login";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { useAuthWrapper } from "auth/wrapper/auth-wrapper";
import { Footer } from "components/Footer";
import { BusinessCategories } from "pages/Business/Business";
import { ServiceType } from "pages/Services/ServiceType";
import { PurchaseHistory } from "pages/history/PurchaseHistory";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { DetailHistory } from "pages/history/DetailHistory";
import { ShoppingCar } from "pages/ShoppingCar/ShoppingCar";
import { Hotels } from "pages/Hotels/Hotels";
import { RoomList } from "pages/Hotels/RoomList";
import { RoomDetail } from "pages/Hotels/RoomDetail";
import { HotelResumen } from "pages/Hotels/HotelResumen";
import { Admin } from "pages/Admin/Admin";
import { SportsCenters } from "pages/SportsCenters/SportsCenters";
import { Search } from "pages/Search/Search";
import { Tickets } from "pages/Pqrs/Tickets";
import { ScheduleAppointment } from "pages/Business/ScreenTypes/ScheduleAppointment";
import { CarnetsComponent } from "pages/Carnet/CarnetsComponent";
import { Profile } from "pages/Profile/Profile";
import LastLocation from "./LastLocation";
import { TIPO_USUARIO } from "utils/constants";
import { VirtualEvent } from "pages/VirtualEvent/VirtualEvent";
import { LightForm } from "pages/LightForm/LightForm";
import { MovieService } from "pages/Business/ScreenTypes/MovieService";

export const AppRouter = () => {
  const windowUrl: string = String(window.location);
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const loginRedirect = useAuthWrapper(windowUrl);



  return (
    <>
      <NavBar />
      <Routes>
        {/* Public routes */}
        <Route
          path="/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/" element={<Navigate to={"/home"} />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/search" element={<Search />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/business/:idBusiness/:idCategory?"
                  element={<BusinessCategories />}
                />
                <Route
                  path="/tipoServicio/:idService"
                  element={<ServiceType />}
                />
                <Route
                  path="/tipoServicioRecaptcha/:idService"
                  element={<ServiceType />}
                />
                <Route path="/hotel/:idServiceCenter" element={<Hotels />} />
                <Route path="/spaces/:idService" element={<SportsCenters />} />
                <Route path="/schedule" element={<ScheduleAppointment />} />
                {isLogged && <Route path="/room-list" element={<RoomList />} />}
                {isLogged && (
                  <Route
                    path="/room-list/room-detail"
                    element={<RoomDetail />}
                  />
                )}
                {isLogged && (
                  <Route path="/room-list/resumen" element={<HotelResumen />} />
                )}
                {isLogged && (
                  <Route path="/carnet" element={<CarnetsComponent />} />
                )}
                {isLogged && authData?.tipoUsuario != TIPO_USUARIO && (
                  <Route
                    path="/historial-compras"
                    element={<PurchaseHistory />}
                  />
                )}
                {isLogged && (
                  <Route
                    path="/detalle-servicio/:idService/:idCompra"
                    element={<DetailHistory />}
                  />
                )}
                {isLogged && (
                  <Route path="/shopping-car" element={<ShoppingCar />} />
                )}

                {isLogged && authData?.tipoUsuario === TIPO_USUARIO && (
                  <Route
                    path="/movie-service"
                    element={<MovieService />}
                  />
                )}

                <Route path="/evento-virtual" element={<VirtualEvent />} />

                <Route path="/social-media/:social/:serviceId" element={<LightForm />} />

                <Route path="/*" element={<Navigate to={"/home"} />} />
              </Routes>
            </PublicRoute>
          }
        />

        {/* Private routes */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Routes>
                {/* <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/historial-compras" element={<PurchaseHistory />} /> */}
              </Routes>
            </PrivateRoute>
          }
        />
      </Routes>
      <LastLocation />
      {/* <Footer /> */}
    </>
  );
};
