import { ThemeProvider } from "@emotion/react";
import { createTheme, Typography } from "@mui/material";
import { HoltelInformationBar } from "components/HoltelInformationBar";
import { Loading } from "components/Loading";
import { RoomCard } from "components/RoomCard";
import { RoomListImages } from "components/RoomListImages";
import { useRoomsAvailable } from "hooks/useRoomsAvailable";
import { HotelForm } from "models/purchaseForm";
import { MRoom } from "models/room";
import { RoomsRequest } from "models/roomsRequest";
import { ServiceImages } from "models/serviceImages";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import manosComfenalco from "../../assets/images/manosComfenalco.png";
import moment from "moment";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { MAYORIA_EDAD_HOTEL, TIPO_USUARIO } from "utils/constants";
import toast from "react-hot-toast";

export const RoomList = () => {
  const { authData } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [hotelData, setHotelData] = useState<HotelForm>(
    JSON.parse(location.state?.data)
  );
  const [totalPersons, setTotalPersons] = useState(0);
  const { getRoomsAvailable, isLoadingRooms } = useRoomsAvailable();
  const [rooms, setRooms] = useState<MRoom[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState<{
    room: MRoom | null;
    index: number | null;
  }>({
    room: null,
    index: null,
  });

  const [roomImages, setRoomImages] = useState<ServiceImages[]>([]);

  const color = hotelData?.color;

  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: color || "#000",
      },
    },
  });

  const beneficiariesSelected =
    authData?.tipoUsuario === TIPO_USUARIO
      ? hotelData?.boxValidator?.grupoFamiliar?.informacionGrupo?.filter(
        (beneficiarie: any) =>
          hotelData?.benefeciariesSelected.some(
            (selected: any) => selected === beneficiarie.identificacion
          )
      )
      : authData?.beneficiarioVOs.filter((beneficiarie: any) =>
        hotelData?.benefeciariesSelected.some(
          (selected: any) => selected === beneficiarie.identificacion
        )
      );

  const guestsAges = {
    adults:
      hotelData?.adults +
      beneficiariesSelected?.filter((beneficiary: any) => {
        const fechaNac = moment(beneficiary?.fechaNacimiento, "YYYY-MM-DD");
        const edad = moment().diff(fechaNac, "years");
        if (edad >= MAYORIA_EDAD_HOTEL) {
          return beneficiary;
        }
      }).length,
    children:
      hotelData?.childs +
      beneficiariesSelected?.filter((beneficiary: any) => {
        const fechaNac = moment(beneficiary?.fechaNacimiento, "YYYY-MM-DD");
        const edad = moment().diff(fechaNac, "years");
        if (edad < MAYORIA_EDAD_HOTEL) {
          return beneficiary;
        }
      }).length,
  };

  useEffect(() => {
    const beneficiariesSelected =
      authData?.tipoUsuario === TIPO_USUARIO
        ? hotelData?.boxValidator?.grupoFamiliar?.informacionGrupo?.filter(
          (beneficiarie: any) =>
            hotelData?.benefeciariesSelected.some(
              (selected: any) => selected === beneficiarie.identificacion
            )
        )
        : authData?.beneficiarioVOs.filter((beneficiarie: any) =>
          hotelData?.benefeciariesSelected.some(
            (selected: any) => selected === beneficiarie.identificacion
          )
        );

    setTotalPersons(
      hotelData?.benefeciariesSelected?.length +
      hotelData?.adults +
      hotelData?.childs
    );
    const onGetRooms = async () => {
      const selectedUser: any[] = beneficiariesSelected.map(
        (beneficiary: any) => {
          return {
            abreviatura: beneficiary?.abreviatura,
            activo: beneficiary?.activo,
            categoria: beneficiary?.categoria,
            codigoError: "0",
            fechaNacimiento: beneficiary?.fechaNacimiento,
            idCate: beneficiary?.idCate,
            idPers: beneficiary?.idPers,
            idPersCotizante: beneficiary?.idPersCotizante,
            idTiid: beneficiary?.idTiid,
            identificacion: beneficiary?.identificacion,
            mensajeError: "No se encontraron errores",
            nombre: beneficiary?.nombre,
          };
        }
      );

      const roomsRequest: RoomsRequest = {
        cantidadAdultosExtra: hotelData?.adults,
        cantidadNinosExtra: hotelData?.childs,
        cantidadHabitaciones: 1,
        fechaIngreso: moment(hotelData?.initialDate).format("YYYY-MM-DD"),
        fechaSalida: moment(hotelData?.finishDate).format("YYYY-MM-DD"),
        codigoZeus: hotelData?.codZeus,
        usuarioSeleccionadosDTOs: selectedUser,
      };

      const roomsRepsonse = await getRoomsAvailable(roomsRequest);

      if (roomsRepsonse.length === 0) {
        toast.error('No se encontraron habitaciones.');

        navigate(-1);
      }

      const roomImages: ServiceImages[] = [];

      if (roomsRepsonse) {
        for (const room of roomsRepsonse) {
          roomImages.push(...room?.imagenes);
        }
      }

      setRooms(roomsRepsonse);
      setRoomImages(roomImages);
      setIsLoading(false);
    };

    onGetRooms();
  }, []);

  const handleNavigate = () => {
    navigate("/room-list/resumen", {
      state: {
        data: JSON.stringify(hotelData),
        color: color,
        selectedRoom: JSON.stringify(selectedRoom.room),
      },
    });
  };

  return (
    <ThemeProvider theme={purpleTheme}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-principal-0 p-8">
          <HoltelInformationBar
            hotelData={hotelData}
            totalPersons={totalPersons}
          />

          {roomImages?.length > 0 && (
            <RoomListImages serviceCenterImages={roomImages} />
          )}

          <div className="flex flex-wrap justify-between w-full p-4 bg-white border rounded-md mt-4 text-principal-600">
            <div className="w-full pb-2 border-b">
              <Typography
                color="primary"
                className="font-bold text-center text-xl"
              >
                Habitaciones - {hotelData?.hotelName}
              </Typography>
            </div>
            {rooms?.length > 0 ? (
              rooms.map((room, index) => (
                <div className="w-full" key={index}>
                  <RoomCard
                    index={index}
                    room={room}
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    color={color}
                    hotelName={hotelData?.hotelName}
                    guestsAges={guestsAges}
                  />
                </div>
              ))
            ) : (
              <div className="flex flex-col w-full items-center pt-4">
                <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                  <img src={manosComfenalco} className="h-16 w-16" />
                  <p className="text-principal-600 font-bold">
                    No se encontraron habitaciones.
                  </p>
                </div>
              </div>
            )}
          </div>

          <Button
            className="fixed bottom-20 right-8 px-6 py-2"
            variant="contained"
            disabled={!selectedRoom?.room}
            onClick={() => handleNavigate()}
          >
            Cotizar
          </Button>
        </div>
      )}
    </ThemeProvider>
  );
};
