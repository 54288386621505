import { BusinessSlider } from "components/BussinesSlider";
import { CategorySlider } from "components/CategorySlider";
import { Loading } from "components/Loading";
import { useBusiness } from "hooks/useBusiness";
import { useCategory } from "hooks/useCategory";
import { Business } from "models/business";
import { Category } from "models/category";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ScreenType } from "./ScreenType";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useRates } from "hooks/useRates";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IndividualSaleForm } from "components/IndividualSaleForm";
import { TIPO_USUARIO, OPCION_VENTA_INDIVIDUAL } from "utils/constants";
import { useBanner } from "hooks/useBanner";
import { BannerData } from "models/banner";
import { Banner } from "components/Banner";
import { set } from "date-fns";

export const BusinessCategories = () => {
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);

  const { activeBusiness, isLoadingBusiness } = useBusiness();

  const { getCategoriesData, isLoadingCategories } = useCategory();

  const { getBannerNegocioImages } = useBanner();

  const [activeCategories, setActiveCategories] = useState<Category[]>([]);

  const [selectedBusiness, setSelectedBusiness] = useState<Business>();

  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { idBusiness, idCategory } = useParams();

  const navigate = useNavigate();

  const [selectedCImages, setSelectedImages] = useState<BannerData[]>([]);

  useEffect(() => {
    const getCategories = async () => {
      if (idBusiness) {
        const selectedBusiness = activeBusiness.find(
          (business) => business.idNegocio === Number(idBusiness)
        );

        selectedBusiness && setSelectedBusiness(selectedBusiness);

        const image = await getBannerNegocioImages(Number(idBusiness));
        setSelectedImages(image);
      }

      const categories = idBusiness && (await getCategoriesData(idBusiness));

      categories && setActiveCategories(categories);

      if (categories && categories?.length > 0) {
        const localStorageBusiness = localStorage.getItem("lastBusinessPath");
        const nowpath = localStorage.getItem("nowPath");

        if (
          nowpath && nowpath.match("(\/*){2,2}")!.length == 2
          && nowpath.split("/")[3]
        ) {
          const splitBusinessPath = nowpath.split("/");
          const idCategory = splitBusinessPath[3];
          const categoryFind = categories.find(
            (cat) => cat.idCategoria === Number(idCategory)
          );
          if (categoryFind) {
            setSelectedCategory(categoryFind);
            navigate(`/business/${idBusiness}/${categoryFind.idCategoria}`);
          }
        } else {
          setSelectedCategory(categories[0]);
          navigate(`/business/${idBusiness}/${categories[0].idCategoria}`);
        }
      }

      setIsLoading(false);
    };

    getCategories();
  }, [idBusiness, isLoading]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {authData?.tipoUsuario == TIPO_USUARIO &&
            authData?.cdaRolCdaOpcions?.some(
              (option: any) =>
                option?.idOpcionCdaOpcion == OPCION_VENTA_INDIVIDUAL
            ) && <IndividualSaleForm />}
          <BusinessSlider
            activeBusiness={
              authData && authData?.tipoUsuario === TIPO_USUARIO
                ? activeBusiness.filter((business) => business.ventaind === "S")
                : activeBusiness
            }
            selectedBusiness={selectedBusiness}
            setSelectedBusiness={setSelectedBusiness}
            selectedCategory={idCategory}
          />

          <CategorySlider
            selectedBusiness={selectedBusiness}
            activeCategories={activeCategories}
            isLoadingCategories={isLoadingCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          {selectedCImages && selectedCImages?.length > 0 && (
            <Banner bannerImage={selectedCImages}></Banner>
          )}

          <ScreenType
            selectedCategory={selectedCategory}
            selectedBusiness={selectedBusiness}
          />
        </>
      )}
    </>
  );
};
