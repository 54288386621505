import { HotelCard } from 'components/HotelCard';
import { Paginatior } from 'components/Paginatior';
import { SmallLoading } from 'components/SmallLoading';
import { useHotels } from 'hooks/useHotels';
import { Business } from 'models/business';
import { Category } from 'models/category';
import { MHotel } from 'models/hotelsResponse';
import React, { useEffect, useState } from 'react'
import { IsCategoryAvailable } from 'utils/CategoryAvailable';
import manosComfenalco from "../../../assets/images/manosComfenalco.png";


interface HotelsProps {
    selectedBusiness?: Business;
    selectedCategory?: Category;
    pageableParam: number;
}

export const HotelsList = ({ selectedBusiness, selectedCategory, pageableParam }: HotelsProps) => {

    // const { bannerImage, isLoadingBanner } = useBanner();

    const { getHotels, isLoadingHotels } = useHotels();

    const [hotels, setHotels] = useState<MHotel[]>([])

    const [page, setPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);

    const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)

    useEffect(() => {

        const getHotelsList = async () => {

            const holtes = await getHotels(page - 1, pageableParam);

            setTotalPages(holtes?.totalPages)

            setHotels(holtes?.content);
        }

        getHotelsList();
    }, [page])

    return (
        <div className='w-full px-4'>
            {
                !isWithinRange ?
                    <>
                        {isLoadingHotels ? (
                            <div className='h-80 w-full'>
                                <SmallLoading />
                            </div>
                        ) : (

                            <>
                                {hotels?.length <= 0 ?
                                    (<div className='flex justify-center h-80'>
                                        No hay hoteles para mostrar.
                                    </div>) : (
                                        <div className='flex flex-wrap justify-around py-6 '>
                                            {
                                                hotels?.map(hotel => (
                                                    <div className='py-3' key={hotel.idCentroServicio}>
                                                        <HotelCard hotel={hotel} screenType="hotel" selectedBusiness={selectedBusiness} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </>

                        )}
                        {hotels?.length > 0 &&
                            <div className='flex justify-center pb-4'>
                                <Paginatior totalPages={totalPages} page={page} setPage={setPage} />
                            </div>
                        }
                    </> :
                    <div className="flex flex-col w-full items-center pt-4">
                        <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                            <img
                                src={manosComfenalco}
                                className="h-16 w-16"
                            />
                            <p className="text-principal-600 font-bold">
                                {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                            </p>
                        </div>
                    </div>
            }

        </div>

    )
}
