import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
// import { Particular } from './Particular';
import {
  COMPRA_INDIVIDUAL_ACTIVA,
  IND_ORIGEN_EDUCACION,
  IND_ORIGEN_ESPACIOS,
  IND_ORIGEN_EVENTOS,
  PARAM_MENSAJE_CUOTAS_EDUCACION,
} from "utils/constants";
import { Radio } from "@mui/material";
import { InfoRate } from "models/infoRate";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import { PurchaseForm, ReservationForm } from "models/purchaseForm";
import { ServiceRates } from "models/servicesRates";
import { AditionalCounter } from "models/aditionalCounter";
import { AditionalFormService } from "./AditionalFormService";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useParam } from "hooks/useParam";
import { ServiceData } from "models/serviceData";

interface RatesProps {
  indOrigen?: string;
  compraIndividual?: string;
  showPriceInline: boolean;
  showDiscount?: boolean;
  rates?: ServiceRates;
  register: UseFormRegister<PurchaseForm>;
  errors: FieldErrors<PurchaseForm>;
  setValue: UseFormSetValue<PurchaseForm>;
  watch: UseFormWatch<PurchaseForm>;
  counterRate?: AditionalCounter[];
  unregister: UseFormUnregister<PurchaseForm>;
  getValues: UseFormGetValues<PurchaseForm>;
  serviceData?: ServiceData
}

export const Rates = ({
  indOrigen,
  compraIndividual,
  showPriceInline,
  rates,
  register,
  errors,
  watch,
  counterRate,
  setValue,
  unregister,
  getValues,
  showDiscount = false,
  serviceData
}: RatesProps) => {
  const isLogged = useSelector((state: RootState) => state.auth.isLogged);

  const { getParamData } = useParam();

  const [mensajeCuotas, setMensajeCuotas] = useState('');

  const atLeastRadioSelected = (
    values: PurchaseForm["benefeciariesSelected"]
  ) => {
    return values ? true : false;
  };

  const atLeastOneCheckBoxSelected = (
    values: PurchaseForm["benefeciariesSelected"]
  ) => values.some(Boolean) || !!counterRate?.find((rate) => rate.counter > 0);

  const selectedBeneficiary = watch("benefeciariesSelected");

  const values = watch();

  useEffect(() => {
    const getParam = async () => {
      const mensajeCuotasParam = await getParamData(PARAM_MENSAJE_CUOTAS_EDUCACION);
      mensajeCuotasParam?.data?.valorParametro &&
        setMensajeCuotas(mensajeCuotasParam?.data?.valorParametro);
    }

    getParam();
  }, [])


  useEffect(() => {
    values?.aditionalForm &&
      values?.aditionalForm?.forEach((element, index) => {
        if (compraIndividual === COMPRA_INDIVIDUAL_ACTIVA) {
          values?.benefeciariesSelected !== element.idTarifa &&
            unregister(`aditionalForm.${index}`);
        } else {
          !values?.benefeciariesSelected?.some(
            (selected: any) => selected === element?.idTarifa
          ) && unregister(`aditionalForm.${index}`);
        }
      });
  }, [selectedBeneficiary]);

  const formatIdentificationNumber = (identification: string) => {
    const firstPart = identification.slice(0, 2);
    const lastPart = identification.slice(-2);

    const middlePartHidden = "*".repeat(identification.length - 4);

    const formattedIdentification = `${firstPart}${middlePartHidden}${lastPart}`;
    return formattedIdentification;
  };

  return (
    <div className="w-full">
      {rates?.tarifasUsuarios?.map((rate: InfoRate, index: number) => (
        <div
          className="flex flex-wrap w-full justify-between items-center pb-3"
          key={index}
        >
          {isLogged ? (
            <div>
              <p className="font-bold text-lg text-principal-650">
                {rate?.nombreUsuario}
              </p>
              <p className="text-lg text-principal-650">
                Categoria {rate?.tarifa?.nombre}
              </p>
            </div>
          ) : (
            <div>
              <p className="font-bold text-lg text-principal-650">
                {rate?.abreviaturaUsuario}{" "}
                {formatIdentificationNumber(rate?.identificacionUsuario)}
              </p>
            </div>
          )}

          {compraIndividual === COMPRA_INDIVIDUAL_ACTIVA ? (
            <div className="flex flex-row items-center pl-2">
              {showPriceInline && (
                <div className="flex">
                  {indOrigen === IND_ORIGEN_ESPACIOS && (
                    <p className="pr-2">Desde</p>
                  )}
                  <p className="font-bold text-lg text-principal-650 pr-2">
                    {rate.tarifa.valor.toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    })}
                  </p>
                  {indOrigen === IND_ORIGEN_ESPACIOS && <p>por hora</p>}
                </div>
              )}
              {!showPriceInline && showDiscount && (
                <div>
                  <p className="font-bold text-lg text-principal-650 pr-2">
                    {rate.tarifa.porcentajeDcto ?? "0"}%
                  </p>
                </div>
              )}
              <Radio
                {...register("benefeciariesSelected", {
                  validate: atLeastRadioSelected,
                })}
                value={rate.identificacionUsuario}
                checked={selectedBeneficiary === rate.identificacionUsuario} // Verifica si el beneficiario actual está seleccionado
              />
            </div>
          ) : (
            <div className="flex flex-row items-center pl-2">
              {showPriceInline && (
                <div className="flex">
                  {indOrigen === IND_ORIGEN_ESPACIOS && (
                    <p className="pr-2">Desde</p>
                  )}
                  <p className="font-bold text-lg text-principal-650 pr-2">
                    {rate.tarifa.valor.toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    })}
                  </p>
                  {indOrigen === IND_ORIGEN_ESPACIOS && <p>por hora</p>}
                </div>
              )}
              {!showPriceInline && showDiscount && (
                <div>
                  <p className="font-bold text-lg text-principal-650 pr-2">
                    {rate.tarifa.porcentajeDcto ?? "0"}%
                  </p>
                </div>
              )}
              <Checkbox
                {...register("benefeciariesSelected", {
                  validate: atLeastOneCheckBoxSelected, // Utilizamos la función de validación personalizada
                })}
                value={rate.identificacionUsuario}
              />
            </div>
          )}

          {selectedBeneficiary === rate.identificacionUsuario &&
            rate.tarifa.descripcion &&
            rate.tarifa.nombreConcepto &&
            rate.tarifa.total && (
              <div className="w-full bg-principal-400/10 overflow-x-scroll">
                <div
                  className="flex flex-col max-w-full justify-center"
                  dangerouslySetInnerHTML={{ __html: rate.tarifa.descripcion }}
                ></div>
                <div className="flex w-full flex-wrap py-2 px-2">
                  <span className="text-lg w-full font-bold">Concepto a pagar</span>
                  <div className="flex w-full  justify-between ">
                    <span className=" text-principal-750 font-semibold">
                      {rate.tarifa?.nombreConcepto}
                    </span>
                    <span className=" text-principal-750 font-semibold">
                      {(rate.tarifa!.valor).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>
                {indOrigen === IND_ORIGEN_EDUCACION &&
                  serviceData?.campoJson.tieneEntrevista &&
                  < div className="flex justify-between py-2 px-2">
                    <span className="text-lg text-principal-650">
                      {mensajeCuotas}
                    </span>
                    <span className="text-lg text-principal-650">
                      {(rate?.tarifa?.total - rate?.tarifa?.valor).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                }
              </div>
            )}
        </div>
      ))
      }

      {
        (indOrigen === IND_ORIGEN_EDUCACION ||
          indOrigen === IND_ORIGEN_EVENTOS) && (
          <div className="border-t pt-2">
            {rates?.tarifasCategoriaD?.map((rate: InfoRate, index) => (
              <div key={index}>
                <div className="flex w-full justify-between items-center pb-3 border-b">
                  <div>
                    <p className="font-bold text-lg text-principal-650">
                      Particular
                    </p>
                    <p className="text-principal-650">
                      De {rate?.tarifa?.edadMinima} a {rate?.tarifa?.edadMaxima}{" "}
                      años
                    </p>
                    <p className="text-lg text-principal-650">
                      Categoria {rate?.tarifa?.nombre}
                    </p>
                  </div>

                  {compraIndividual === COMPRA_INDIVIDUAL_ACTIVA ? (
                    <div className="flex flex-row items-center pl-2">
                      {showPriceInline && (
                        <div>
                          <p className="font-bold text-lg text-principal-650 pr-2">
                            {rate.tarifa.valor.toLocaleString("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            })}
                          </p>
                        </div>
                      )}
                      <Radio
                        {...register("benefeciariesSelected", {
                          validate: atLeastRadioSelected,
                        })}
                        value={String(rate?.tarifa?.idTarifa)}
                        checked={
                          selectedBeneficiary === String(rate?.tarifa?.idTarifa)
                        } // Verifica si el beneficiario actual está seleccionado
                      />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center pl-2">
                      {showPriceInline && (
                        <div>
                          <p className="font-bold text-lg text-principal-650 pr-2">
                            {rate.tarifa.valor.toLocaleString("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            })}
                          </p>
                        </div>
                      )}
                      <Checkbox
                        {...register("benefeciariesSelected", {
                          validate: atLeastOneCheckBoxSelected, // Utilizamos la función de validación personalizada
                        })}
                        value={rate?.tarifa?.idTarifa}
                      />
                    </div>
                  )}
                </div>
                {!showPriceInline &&
                  selectedBeneficiary === String(rate?.tarifa?.idTarifa) && (
                    <div className="w-full bg-principal-400/10 overflow-x-scroll">
                      <div
                        className="max-w-full"
                        dangerouslySetInnerHTML={{
                          __html: rate.tarifa.descripcion,
                        }}
                      ></div>

                      <div className="py-2 px-2">
                        <span className="flex justify-between text-lg text-principal-650">
                          {mensajeCuotas}
                        </span>

                        <span className="text-lg text-principal-650">
                          {(rate?.tarifa?.total - rate?.tarifa?.valor).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  )}
                <div>
                  {compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
                    ? selectedBeneficiary == rate?.tarifa?.idTarifa && (
                      <div className="w-full pb-3">
                        <AditionalFormService
                          errors={errors}
                          formIndex={index}
                          register={register}
                          setValue={setValue}
                          idTarifa={rate?.tarifa?.idTarifa}
                        />
                      </div>
                    )
                    : selectedBeneficiary &&
                    selectedBeneficiary?.find(
                      (beneficiary: any) =>
                        Number(beneficiary) === rate?.tarifa?.idTarifa
                    ) && (
                      <div className="w-full pb-3">
                        <AditionalFormService
                          errors={errors}
                          formIndex={index}
                          register={register}
                          setValue={setValue}
                          idTarifa={rate?.tarifa?.idTarifa}
                        />
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>
        )
      }

      {
        errors.benefeciariesSelected && (
          <p className="text-red-500">Selecciona al menos un beneficiario.</p>
        )
      }
    </div >
  );
};
