import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJWT } from "store/slices/JWT";
import { AppDispatch, RootState } from "store";
import { AppRouter } from "router/AppRouter";
import { NavBar } from "components/NavBar";
import { Loading } from "components/Loading";
import { getCredentials } from "store/slices/credentials";
import { setAuthData, setAuthSession } from "store/slices/auth";
import { Auth0Provider } from "@auth0/auth0-react";
import { decrypt } from "utils/security/AesUtil";

export const Preload = () => {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.jwt.token);
  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );
  const fetchData = async () => {
    try {
      // const param = getParamData('URL_AUTH0');
      await dispatch(getJWT()); // Esperar la carga del token
      await dispatch(getCredentials()); // Esperar la carga de las credenciales

      const userData =
        localStorage.getItem("userData") &&
        decrypt(localStorage.getItem("userData")!);

      const sessionData =
        localStorage.getItem("sessionData") &&
        decrypt(localStorage.getItem("sessionData")!);

      userData && dispatch(setAuthData(userData));
      sessionData && dispatch(setAuthSession(sessionData));
    } catch (error) {
      // Manejar errores
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {Object.keys(credentials).length > 0 ? (
        <Auth0Provider
          domain={credentials.domainAdmin}
          clientId={credentials.clientIdAdmin}
          authorizationParams={{
            redirect_uri: window.location.origin + "/delagenteweb/home",
          }}
        >
          {!token.token || token.token == "" ? (
            <>
              <NavBar />
              <Loading />
            </>
          ) : (
            <AppRouter />
          )}
        </Auth0Provider>
      ) : (
        <>
          <NavBar />
          <Loading />
        </>
      )}
    </>
  );
};
