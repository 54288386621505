import moment from "moment";
import React from "react";
import { IND_ORIGEN_CONVENIOS, IND_ORIGEN_EDUCACION, IND_ORIGEN_ESPACIOS, IND_ORIGEN_HOTELES, PARAM_RANGO_FECHA } from 'utils/constants';

interface SaleInfoModel {
  detailInfo: any;
  indOrigen: string;
}

export default function SaleInfo({ detailInfo, indOrigen }: SaleInfoModel) {


  return (
    <div className="flex flex-col">
      <div className="flex flex-row  mt-3 p-3 justify-between items-center">
        <p className="font-bold text-principal-600 text-lg pr-4">
          Fecha visita
        </p>

        <p className="font-semibold text-principal-600 text-lg pr-4">
          {indOrigen === IND_ORIGEN_ESPACIOS ?
            moment(detailInfo?.fechaVisita).format("YYYY/MM/DD") :
            indOrigen === IND_ORIGEN_HOTELES ?
              moment.utc(detailInfo?.fechaVisita).format("dddd DD [de] MMMM YYYY") :
              moment.utc(detailInfo?.fechaVisita).format("YYYY/MM/DD hh:mm A")
          }
        </p>
      </div>

      {indOrigen === IND_ORIGEN_ESPACIOS &&
        <div className="flex flex-row p-3 justify-between items-center">
          <p className="font-bold text-principal-600 text-lg pr-4">
            Hora reserva
          </p>

          <p className="font-semibold text-principal-600 text-lg pr-4">
            {moment.utc(detailInfo?.fechaVisita).format("hh:mm A")} - {moment.utc(detailInfo?.fechaFinCompra).format("hh:mm A")}
          </p>
        </div>}

      <hr />

      <div className="flex flex-col  p-3 items-left">
        <p className="font-bold text-principal-600 text-lg pr-4">Asistentes</p>

        {detailInfo.cdaUsuarioServicioDTO.map((usuarioServicio: any) => (
          <div className="flex flex-row justify-between items-center mt-3">
            <div className="flex flex-col">
              <p className="font-semibold text-principal-600 text-sm pr-4">
                {` ${usuarioServicio.primerApellido
                  ? usuarioServicio.primerApellido
                  : ""
                  } ${usuarioServicio.segundoApellido &&
                    usuarioServicio.segundoApellido !== ""
                    ? usuarioServicio.segundoApellido
                    : ""
                  } ${usuarioServicio.primerNombre &&
                    usuarioServicio.primerNombre !== ""
                    ? usuarioServicio.primerNombre
                    : ""
                  }
                ${usuarioServicio.segundoNombre &&
                    usuarioServicio.segundoNombre !== ""
                    ? usuarioServicio.segundoNombre
                    : ""
                  }
                `}
              </p>
              <p className="font-semibold text-principal-600 text-sm pr-4">
                categoria {usuarioServicio.categoria}
              </p>
            </div>
            <div className="flex flex-col">
              {indOrigen &&
                indOrigen !== "" &&
                indOrigen === IND_ORIGEN_CONVENIOS ? (
                <>
                  <p className="font-semibold text-principal-600 text-sm pr-4">
                    {usuarioServicio.porcentajeDcto}%
                  </p>
                </>
              ) : (
                <>
                  {indOrigen !== IND_ORIGEN_HOTELES &&
                    <p className="font-semibold text-principal-600 text-sm pr-4">
                      {usuarioServicio.valor.toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                      })}
                    </p>
                  }

                </>
              )}
            </div>
          </div>
        ))}
        <hr className="mt-5" />
      </div>

      <div className="flex flex-col items-left p-3 ">
        {detailInfo.cdaUsuarioFacturaDTO.map((usuarioFactura: any) => (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col">
              <p className=" text-principal-650 text-sm pr-4">
                {` ${usuarioFactura.primerApellido &&
                  usuarioFactura.primerApellido !== ""
                  ? usuarioFactura.primerApellido
                  : ""
                  } ${usuarioFactura.segundoApellido
                    ? usuarioFactura.segundoApellido
                    : ""
                  } ${usuarioFactura.primerNombre &&
                    usuarioFactura.primerNombre !== ""
                    ? usuarioFactura.primerNombre
                    : ""
                  }
                ${usuarioFactura.segundoNombre &&
                    usuarioFactura.segundoNombre !== ""
                    ? usuarioFactura.segundoNombre
                    : ""
                  }
                `}
              </p>
              <p className="text-principal-650 text-sm pr-4">
                {`${usuarioFactura.tipoIdentificacion}: ${usuarioFactura.numeroIdentificacion}`}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-principal-600 text-sm pr-4 ">
                Total
              </p>
              <p className="font-semibold text-principal-600 text-lg pr-4">
                {detailInfo.valorTotal.toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
