import { Typography } from '@mui/material'
import { MQuotas } from 'models/quotas'
import React from 'react'

interface QuotaCardProps {
    title: string;
    quotas: MQuotas | undefined;
    firstParam?: string;
    secondParam?: string;
    value1Param?: string;
    cupo?: number;
}

export const SubsidyCreditCard = ({ quotas, title, firstParam, secondParam, value1Param, cupo }: QuotaCardProps) => {

    return (
        <div className='flex flex-col justify-center items-center w-full md:w-9/20 bg-white border rounded-xl shadow p-8'>
            <p className='font-bold text-principal-500'>{title}</p>
            {firstParam && <span className='text-center' color='primary'>
                {firstParam}
            </span>}
            {
                quotas &&
                <div className='py-3'>
                    <Typography className='text-2xl' color='primary'>{Number(cupo).toLocaleString('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        minimumFractionDigits: 0,
                    })} </Typography>
                </div>
            }
            <span className='text-center text-principal-250 font-bold' color='primary'>{value1Param} MV</span>
            {secondParam && <span className='text-center' color='primary'>
                {secondParam}
            </span>}

            <div className='flex flex-col w-full py-2'>
                <span className='font-bold' color='primary'>
                    Información importate
                </span>
                <span className='' color='primary'>
                    La aprobación, está sujeta al cumplimiento de las políticas de crédito.
                </span>
            </div>
        </div>
    )
}
