import { ServiceData } from "models/serviceData";
import { ServiceImages } from "models/serviceImages";
import React from "react";
import { Banner } from "./Banner";
import { Typography } from "@mui/material";
import { Location } from "./Location";
import { AditionalInformation } from "./AditionalInformation";
import { LinksAndFiles } from "./LinksAndFiles";
import { MFile } from "models/MFile";
import NubeEventoVirtual from "assets/images/nube_evento_virtual.svg";
import { useNavigate } from "react-router-dom";

interface ServiceDescriptionProp {
  bannerImages: ServiceImages[];
  serviceData?: ServiceData;
  fileData?: MFile[];
  idCompra?: string;
}

export const ServiceDescription = ({
  bannerImages,
  serviceData,
  fileData,
  idCompra,
}: ServiceDescriptionProp) => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <Banner bannerImage={bannerImages} />
      <div className="px-4 py-4">
        <div className="py-2">
          <div className="flex justify-between items-center pt-8">
            <Typography className="font-bold text-2xl" color="primary">
              {serviceData?.nombre}
            </Typography>
            {serviceData?.campoJson?.virtual === "S" && (
              <div
                className="flex flex-col items-center text-center cursor-pointer w-12 h-12"
                onClick={
                  idCompra
                    ? () => navigate(`/evento-virtual?idCompra=${idCompra}`)
                    : undefined
                }
              >
                <img src={NubeEventoVirtual} className="h-15 w-15 " />
                <p className="w-full text-principal-250 font-bold text-sm">
                  Virtual
                </p>
              </div>
            )}
          </div>
          <Typography className="text-lg font-bold pt-2 text-principal-600">
            {serviceData?.nombreCentro}
          </Typography>
        </div>
        {(serviceData?.direccion || serviceData?.telefono) && (
          <div className="flex flex-col w-full text-principal-600">
            <Location
              location={serviceData?.direccion}
              phoneNumer={serviceData?.telefono}
            />
          </div>
        )}
        <div
          className="py-4 text-principal-650  text-justify"
          dangerouslySetInnerHTML={
            serviceData && { __html: serviceData.descripcion }
          }
        />
        <div>
          <AditionalInformation serviceData={serviceData} />
        </div>

        {fileData && <LinksAndFiles fileData={fileData} />}
      </div>
    </div>
  );
};
