import { ServiceImages } from 'models/serviceImages'
import React from 'react'
import { ServiceCenter } from 'models/serviceCenter';
import { HotelImages } from './HotelImages';
import { PopularService } from 'models/popularService';
import { PopularServices } from './PopularServices';

interface ServiceDescriptionProp {
    serviceCenterImages: ServiceImages[];
    serviceCenterData?: ServiceCenter,
    popularServices?: PopularService[]
}

export const ServiceCenterDescription = ({ serviceCenterImages, serviceCenterData, popularServices }: ServiceDescriptionProp) => {
    return (
        <div className='w-full bg-[#FBFBFB]'>
            <HotelImages serviceCenterImages={serviceCenterImages} />

            <div className='px-4 py-4 mt-4 bg-white border-lg  text-justify shadow'>
                {serviceCenterData?.descripcion}
            </div>

            <div className='px-4 py-4 mt-4 bg-white border-lg shadow'>
                <p className='font-bold text-principal-600 text-lg'>Servicios populares</p>
                <PopularServices popularServices={popularServices} />
            </div>

        </div>
    )
}
